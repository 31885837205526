import React, { useState } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material/styles';

import MetaLayout from './meta-layout';
import { FCR } from '../../util';
import theme from '../../util/theme';
import './layout.css';

const Layout: FCR = (props) => {
    // const [theme, setTheme] = useState(theme);

    // const switchTheme = (): void => {
    //     setTheme(theme);
    // };

    // let theme = createTheme();
    // theme = responsiveFontSizes(theme);

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <MetaLayout switchTheme={theme}>{props.children}</MetaLayout>
        </ThemeProvider>
    );
};

export default Layout;
