import React from 'react';
import { styled } from '@mui/material/styles';

import Header from './header';
import Footer from './footer';
import { FCR } from '../../util';

const MetaLayoutContent = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.default
}));

const MainContent = styled('main')(({ theme }) => ({
    marginTop: 0,
    marginBottom: 40,
    backgroundColor: theme.palette.background.default,
    fontSize: '0.9rem',
    '@media screen and (min-width:600px)': {
        fontSize: '1.0rem'
    }
}));

type MetaLayoutProps = {
    switchTheme: () => void;
};

const MetaLayout: FCR<MetaLayoutProps> = (props) => {
    // const { title } = useSiteMetadata();
    const title = 'J-Testing';
    return (
        <MetaLayoutContent>
            <Header switchTheme={props.switchTheme} siteTitle={title} />
            <MainContent>{props.children}</MainContent>
            <Footer />
        </MetaLayoutContent>
    );
};

export default MetaLayout;
