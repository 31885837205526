import React from 'react';
import { Container, Typography, Link } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FC } from '../../util';

const FooterContent = styled('footer')(({ theme }) => ({
    display: 'flex', // 中央寄せ
    justifyContent: 'center', // 中央寄せ
    alignItems: 'center', // 中央寄せ
    padding: theme.spacing(3, 2),
    marginTop: 'auto',
    bottom: '0',
    width: '100%',
    backgroundColor: theme.palette.background.paper
}));

const FooterText = styled(Typography)(() => ({
    textAlign: 'center', // 中央寄せ
    color: 'darkgray',
    fontSize: '0.8rem',
    '@media screen and (min-width:600px)': {
        fontSize: '1.0rem'
    }
}));

const Footer: FC = () => {
    return (
        <FooterContent>
            <Container maxWidth="md">
                <FooterText variant="body1" color="textSecondary">
                    © {new Date().getFullYear()} Copyright
                    {` `}
                    <Link href="https://www.e-coms.co.jp" color="inherit">
                        e-communications.
                    </Link>
                    {` `}
                    All rights reserved.
                </FooterText>
            </Container>
        </FooterContent>
    );
};

export default Footer;
