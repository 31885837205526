import deepMerge from 'deepmerge';
import { createMuiTheme, responsiveFontSizes, ThemeOptions, Theme } from '@mui/material';
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    components: {
        // コンポーネント名
        MuiCssBaseline: {
            '@global': {
                '*::-webkit-scrollbar': {
                    width: '0.5rem'
                },
                '*::-webkit-scrollbar-thumb': {
                    background: '#888'
                },
                '*::-webkit-scrollbar-thumb:hover': {
                    background: '#555'
                }
            }
        }
    },
    palette: {
        type: 'light',
        primary: {
            main: '#1582e8'
        },
        secondary: {
            main: '#7cd2f7'
        },
        error: {
            main: '#f03e3e'
        },
        warning: {
            main: '#f0a04f'
        },
        info: {
            main: '#709ecc'
        },
        success: {
            main: '#4fe054'
        },
        text: {
            primary: '#343a40',
            secondary: '#2e3133',
            hint: '#363c42',
            disabled: '#48494a'
        },
        background: {
            default: '#f3f3f3',
            paper: '#ffffff'
        }
    },
    // フォントサイズは、16px が基準、現在の読みやすさを優先にしている
    typography: {
        fontSize: 14,
        h4: {
            borderLeft: 'solid 5px #f50057',
            paddingLeft: 8
        }
    }
});

export const lightMode = createTheme({
    palette: {
        type: 'light',
        primary: {
            main: '#1582e8'
        },
        secondary: {
            main: '#7cd2f7'
        },
        error: {
            main: '#f03e3e'
        },
        warning: {
            main: '#f0a04f'
        },
        info: {
            main: '#709ecc'
        },
        success: {
            main: '#4fe054'
        },
        text: {
            primary: '#343a40',
            secondary: '#2e3133',
            hint: '#363c42',
            disabled: '#48494a'
        },
        background: {
            default: '#f2f2f2',
            paper: '#dce3f2'
        }
    },
    typography: {
        h4: {
            borderLeft: 'solid 5px #f50057',
            paddingLeft: 8
        }
    }
});

export const darkMode = createTheme({
    palette: {
        type: 'dark',
        primary: {
            main: '#0d3054'
        },
        secondary: {
            main: '#0b1e26'
        },
        error: {
            main: '#870505'
        },
        warning: {
            main: '#964c00'
        },
        info: {
            main: '#09539c'
        },
        success: {
            main: '#034d06'
        },
        text: {
            primary: '#f8f9fa',
            secondary: '#e4e7eb',
            hint: '#bbbcbd',
            disabled: '#ccc'
        },
        background: {
            default: '#1c2c33',
            paper: '#162024'
        }
    },
    typography: {
        h4: {
            borderLeft: 'solid 5px #f50057',
            paddingLeft: 8
        }
    }
});

theme.typography.h1 = {
    fontSize: '1.6rem'
};

theme.typography.h4 = {
    position: 'relative',
    paddingLeft: '20px', // 四角形とテキストの間にスペースを作る
    '&::before': {
        content: '""',
        position: 'absolute',
        left: 0,
        bottom: 0,
        width: '10px', // 四角形の幅
        height: '10px', // 四角形の高さ
        borderBottom: '2px solid', // 四角形の下線
        borderLeft: '2px solid' // 四角形の左線
    }
};
// const themes: { [key: string]: Theme } = {
//     light: makeTheme(light),
//     dark: makeTheme(dark)
// };

export const themeCookie = 'clthmvar';

export default theme;
