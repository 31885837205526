import React, { useState } from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { AppBar, Toolbar, Typography, Link, Button, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { BsToggleOn, BsToggleOff } from 'react-icons/bs';
import { FC } from '../../util';
import logo from '../../images/jt-logo.png';

const AppBarContent = styled(AppBar)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    '& .MuiToolbar-root': {
        display: 'flex',
        justifyContent: 'space-between'
    }
}));

const LinkContent = styled(Link)(({ theme }) => ({
    textDecoration: 'none'
}));

export interface HeaderProps {
    switchTheme: () => void;
    siteTitle?: string;
}

const Header: FC<HeaderProps> = (props) => {
    const [showOn, setShowOn] = useState<boolean>(false);

    const onSwitch = (): void => {
        setShowOn((prev) => !prev);
        props.switchTheme();
    };

    return (
        // elevation={0} で影を消す
        <AppBarContent sx={{ mb: 0, pb: 0 }} position="static" elevation={0} component="header">
            <Toolbar sx={{ m: 1 }}>
                {/* <LinkContent to="/" component={GatsbyLink} color="inherit"> */}
                <img src={logo} alt="J-Tesingお知らせ" className="logo" />
                {/* </LinkContent> */}
            </Toolbar>
        </AppBarContent>
    );
};

export default Header;
