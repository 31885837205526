exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contents-draft-tsx": () => import("./../../../src/pages/contents/draft.tsx" /* webpackChunkName: "component---src-pages-contents-draft-tsx" */),
  "component---src-pages-news-draft-tsx": () => import("./../../../src/pages/news/draft.tsx" /* webpackChunkName: "component---src-pages-news-draft-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-templates-contents-tsx": () => import("./../../../src/templates/contents.tsx" /* webpackChunkName: "component---src-templates-contents-tsx" */),
  "component---src-templates-news-list-tsx": () => import("./../../../src/templates/news-list.tsx" /* webpackChunkName: "component---src-templates-news-list-tsx" */),
  "component---src-templates-news-tsx": () => import("./../../../src/templates/news.tsx" /* webpackChunkName: "component---src-templates-news-tsx" */)
}

