/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
export function shouldUpdateScroll() {
    // ヘージ遷移時にページトップに戻る
    // これを入れないと、ページ遷移時にスクロール位置が保持されてしまう
    window.scrollTo(0, 0);
    return false;
}
